@font-face {
  font-family: "tahoma-regular";
  src:url("/fonts/tahoma/Tahoma-Regular-font.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Now Text";
  src: url("/fonts/HelveticaNowText-Regular.woff2") format("woff2"),
    url("/fonts/HelveticaNowText-Regular.woff") format("woff"),
    url("/fonts/HelveticaNowText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
